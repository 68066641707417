import styles from "../stylesheets/EpgForm.module.css"
import EntryInput from "./inputs/EntryInput"
import {Autocomplete, Checkbox, FormControlLabel, FormGroup, TextField} from "@mui/material"
import {useEffect, useState} from "react"
import {fetchEpg, publishEpg} from "../actions/epg"
import {addErrorAlertWithAutoRemoval} from "../actions"
import {useDispatch} from "react-redux"

export default function EpgControls({gamePk, isStarted}) {
    const dispatch = useDispatch()

    //must include `""` as a value + option for Autocomplete component to be in controlled state
    const mediaStateOptions = ["MEDIA_ON", "MEDIA_ARCHIVE", "MEDIA_OFF", ""]
    const defaultTitle = "MiLBTV"
    const defaultFeedType = "ND"

    const [epgItem, setEpgItem] = useState({})
    const [mediaId, setMediaId] = useState("")
    const [contentId, setContentId] = useState("")
    const [mediaState, setMediaState] = useState("")
    const [freeGame, setFreeGame] = useState(false)

    useEffect(() => {
        const setEpg = async () => {
            try {
                console.log("Fetch Epg Item")
                let e = await fetchEpg(gamePk)
                if (Object.keys(e).length === 0) {
                    return
                }

                setEpgItem(e)
                setMediaId(e.mediaId)
                setMediaState(e.mediaState)
                setFreeGame(e.freeGame)
                setContentId(e.contentId)
            } catch (e) {
                addErrorAlertWithAutoRemoval(`Could not fetch epg item for gamePk: ${gamePk}.`)(dispatch)
            }
        }
        if (isStarted) {
            setEpg()
        }
    }, [gamePk, isStarted])

    const checkAndSetEpgItem = async () => {
        setTimeout(async () => {
            let epgStatusCheck = await fetchEpg(gamePk)
            if (epgStatusCheck.toString() !== epgItem.toString()) {
                //dispatch error
                addErrorAlertWithAutoRemoval(`Failed to update epg item`)(dispatch)
            }

            setEpgItem(epgStatusCheck)
        }, 10000);
    }

    const handlePublish = async (e) => {
        e.preventDefault()

        if (contentId) {
            epgItem.contentId = contentId
            epgItem.id = parseInt(contentId)
        }
        if (mediaState) {
            epgItem.mediaState = mediaState
        }
        if (mediaId) {
            epgItem.mediaId = mediaId
        }
        epgItem.freeGame = freeGame
        epgItem.title = defaultTitle
        epgItem.feedType = defaultFeedType

        publishEpg(gamePk, epgItem).then(() => {
            checkAndSetEpgItem()
        })
    }

    return (
        <div>
            <FormGroup id="epg" className={styles.inputGroupGame}>
                <EntryInput label="Title" labelClass={styles.label} disabled={true} defaultValue={defaultTitle}/>
                <EntryInput label="FeedType" labelClass={styles.label} disabled={true} defaultValue={defaultFeedType}/>
                <EntryInput label="Media ID"
                            labelClass={styles.label}
                            minLength={4}
                            maxLength={10}
                            defaultValue={mediaId}
                            onChange={(e) => setMediaId(e.target.value)}/>
                <EntryInput label="Content ID"
                            labelClass={styles.label}
                            minLength={4}
                            maxLength={10}
                            defaultValue={contentId}
                            onChange={(e) => setContentId(e.target.value)}/>
                <Autocomplete disablePortal
                              options={mediaStateOptions}
                              value={mediaState}
                              onChange={(e) => setMediaState(e.target.innerText)}
                              sx={{fontSize: 20}}
                              renderInput={(params) => <TextField {...params}
                                                            variant="standard"
                                                            fullWidth
                                                            label="Media State"
                                                            labelclass={styles.label}
                                                            inputProps={{
                                                                ...params.inputProps,
                                                                style: {
                                                                    paddingBottom: 12
                                                                }
                                                            }}
                        />}
                    />
                    <FormControlLabel
                        label="Free Game"
                        //disable for now until use case handled later
                        disabled={true}
                        control={
                            <Checkbox
                                checked={freeGame}
                                onChange={() => setFreeGame(!freeGame)}
                            />
                        }
                    />
                <div className={styles.row}>
                    <button className={styles.saveButton} onClick={(e)=>handlePublish(e)}>
                        Publish
                    </button>
                </div>
                </FormGroup>
            </div>
    )
}