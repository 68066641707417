import React, {useEffect, useMemo, useRef, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useHistory, useParams} from 'react-router-dom'
import 'react-dropdown/style.css'
import NextGenGameDropdown from '../NextGenGameDropdown'
import NavBar from '../NavBar'
import BeatLoader from 'react-spinners/BeatLoader'
import {DesktopDatePicker, DesktopTimePicker, LocalizationProvider} from '@mui/lab'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import {Checkbox, FormControlLabel, FormGroup, TextField} from "@material-ui/core"
import {CerebroError} from '../../errors'
import {addErrorAlertWithAutoRemoval, addInfoAlertWithAutoRemoval,} from '../../actions'
import {
    applyYodaSlate,
    cancelGame,
    fetchAssetsAndPlaybackUrl,
    fetchGamePipeline, findGamePipeline,
    GameStreamAction,
    liftYodaSlate,
    patchGamePipeline,
    postGamePipelineStreamAction,
    updateAdBreak
} from '../../actions/games'
import {GAME_ASSET_TYPE, GAME_STATUS} from '../../utils'
import styles from '../../stylesheets/AssetDetail.module.css'
import '../../stylesheets/ReactTags.css'
import LeagueDropdown from "../LeagueDropdown"
import VenueDropdown from "../VenueDropdown"
import PipelineStatus from "./PipelineStatus"
import {ButtonWithPopup} from "./ButtonWithPopup"
import {StatusContent} from "./StatusContent"
import YodaSlates from "./YodaSlates"
import {fetchToken} from "../../actions/auth"
import {VideoPlayer} from './VideoPlayer'
import {isEpochBeginning} from "../../utils/date-utils"
import {getCookie} from "../../actions/cookies"
import AdBreakButtons, {SCTE_35_CUE_IN} from "../AdBreakButtons"
import settings from "../../settings"
import Collapsible from "../Collapsible"
import EpgControls from "../Epg";

// gets the GameEventPipeline for this page from redux
const selectGameEventPipelineByGamePkAndFeedType = (state, id, gamePk, feedType) => {
    return findGamePipeline(id, state.games.filter(game => game.gamePk === gamePk)
        ?.flatMap(game => game.pipelines)
        .filter(pipeline => pipeline.feedType?.code === feedType)
    )
}

// gets a GameAsset from redux
const selectGameAssetByGameEventPipelineIdAndAssetCode = (state, gameEventPipelineId, assetCode) => {
    return state.gameAssets.find(asset =>
        asset.gameEventPipeline.id === gameEventPipelineId && asset.assetType.code === assetCode
    )
}

// gets the playback uri from a GameAsset
const getAssetMediaLocation = (gameAsset) => {
    return gameAsset?.assetMediaLocations.find(location => location.mediaLocation.code === 'CDN_URL')
}

// gets the Cancel Event for this page from redux
const selectCancelEventByGamePkAndFeedType = (state, gamePk, feedType) => {

    return state.provisionEvents.find(provisionEvent =>
        provisionEvent.gamePk === gamePk && provisionEvent.feedType === feedType
    )
}

function adBreakisOver(adBreakDuration, adBreakStartTime) {
    return adBreakDuration !== null && adBreakDuration < (secondsAdRunningFor(adBreakStartTime))
}

function secondsAdRunningFor(adBreakStartTime) {
    const currentTime = new Date().getTime() / 1000
    const startOfAd = new Date(adBreakStartTime).getTime() / 1000
    return currentTime - startOfAd
}

function adBreakIsActive(gameEventPipeline) {
    return gameEventPipeline.adBreakStartTime !== null && !adBreakisOver(gameEventPipeline.adBreakDurationInSeconds, gameEventPipeline.adBreakStartTime)
}

function adBreakInactive(gameEventPipeline) {
    return gameEventPipeline.adBreakStartTime === null || adBreakisOver(gameEventPipeline.adBreakDurationInSeconds, gameEventPipeline.adBreakStartTime)
}

export default function AssetDetail() {
    // route params
    const gamePk = parseInt(useParams().gamePk)
    const queryParams = new URLSearchParams(window.location.search)
    const feedType = queryParams.get("feedType")
    const gamePipelineId = queryParams.get("gamePipelineId") ? parseInt(queryParams.get("gamePipelineId"), 10) : null
    let history = useHistory()
    const milbSupportedSportIds = settings.SUPPORTED_SPORT_IDS.filter(l => l.type === 'milb').map(league => league.id)

    // redux state
    const dispatch = useDispatch()
    const gameEventPipeline = useSelector(state => selectGameEventPipelineByGamePkAndFeedType(state, gamePipelineId, gamePk, feedType))
    const gameDateTime = useMemo(() => gameEventPipeline?.gameDateTime, [gameEventPipeline])
    const sportIds = useMemo(() => [gameEventPipeline?.feedType?.sportId], [gameEventPipeline?.feedType?.sportId])
    const yodaSlates = useSelector(state => state.yodaSlates)
    const currentSlateUrl = useMemo(() => yodaSlates?.find(slate => slate.uri === gameEventPipeline?.activeSlate)?.thumbnail, [gameEventPipeline?.activeSlate, yodaSlates])
    const [startAdClicked, setStartAdClicked] = useState(false)
    const [stopAdClicked, setStopAdClicked] = useState(false)
    const [adBreakActive, setAdBreakActive] = useState(false)
    //convert to a Number with +
    const [adBreakDurationInSeconds, setAdBreakDurationInSeconds] = useState(+getCookie("adBreakDuration")
        || +settings.DEFAULT_MILB_AD_BREAK_DURATION_IN_SECONDS)
    const [timeLeftAdInMillis, setTimeLeftAdInMillis] = useState(0)
    const [timeLapsedInSeconds, setTimeLapsedInSeconds] = useState(0)
    const [hideTimer, setHideTimer] = useState(false)

    const preProcessGameAsset = useSelector(state =>
        selectGameAssetByGameEventPipelineIdAndAssetCode(state, gameEventPipeline?.id, GAME_ASSET_TYPE.PRE)
    )
    const postProcessGameAsset = useSelector(state =>
        selectGameAssetByGameEventPipelineIdAndAssetCode(state, gameEventPipeline?.id, GAME_ASSET_TYPE.POST)
    )

    const playbackUrls = {
        pre: getAssetMediaLocation(preProcessGameAsset)?.uri,
        post: postProcessGameAsset?.cdnUri
    }
    const primaryPlaybacks = {
        pre: preProcessGameAsset?.primaryPlayback,
        post: postProcessGameAsset?.primaryPlayback,
    }

    // local state
    const [isEventActionUpdating, setIsEventActionUpdating] = useState(false)
    const [isTogglingSlate, setIsTogglingSlate] = useState(false)
    const [isTogglingCancel, setIsTogglingCancel] = useState(false)
    const prevStatus = useRef(null)
    const prevActiveSlate = useRef(null)
    const preRef = useRef(null)
    const postRef = useRef(null)
    const [accessToken, setAccessToken] = useState('')
    const timeoutRef = useRef()

    const isLive = gameEventPipeline?.started && !gameEventPipeline?.ended
    const isOver = gameEventPipeline?.status === GAME_STATUS.ENDED || gameEventPipeline?.status === GAME_STATUS.ENDING
    const isProvisioned = gameEventPipeline?.status === GAME_STATUS.PROVISIONED
    const isStarted = gameEventPipeline?.status !== GAME_STATUS.STARTING && gameEventPipeline?.started

    const cancelEvent = useSelector(state => selectCancelEventByGamePkAndFeedType(state, gamePk, feedType))

    // hydrate page data
    useEffect(() => {
        fetchGamePipeline(gamePk, feedType, gamePipelineId)(dispatch)
        fetchAssetsAndPlaybackUrl(gamePk, feedType)(dispatch)
    }, [dispatch, gamePk, feedType, gamePipelineId])

    useEffect(() => {
        const setToken = async () => {
            console.log("Fetch Access Token")
            setAccessToken(await fetchToken())
        }
        setToken()
    }, [accessToken])
    
    useEffect(() => {
            if (!gameEventPipeline)
                return

            if (adBreakInactive(gameEventPipeline)) {
                setAdBreakActive(false)
            } else if (adBreakIsActive(gameEventPipeline)) {
                setAdBreakActive(true)
                const timeLapsed = secondsAdRunningFor(gameEventPipeline.adBreakStartTime)
                setTimeLapsedInSeconds(timeLapsed)
                if (gameEventPipeline.adBreakDurationInSeconds !== null) {
                    const timeLeftInAd = gameEventPipeline.adBreakDurationInSeconds - timeLapsed
                    setTimeLeftAdInMillis(timeLeftInAd * 1000)
                    timeoutRef.current = setTimeout(() => {
                        setAdBreakActive(false)
                    }, timeLeftInAd * 1000)
                } else {
                    setTimeLeftAdInMillis(0)
                }
            }

            if (isEventActionUpdating && prevStatus.current !== gameEventPipeline.status) {
                setIsEventActionUpdating(false)
                prevStatus.current = gameEventPipeline.status
            }
            if (isTogglingSlate && prevActiveSlate.current !== gameEventPipeline.activeSlate) {
                setIsTogglingSlate(false)
                prevActiveSlate.current = gameEventPipeline.activeSlate
            }
            if (startAdClicked && adBreakIsActive(gameEventPipeline)) {
                setStartAdClicked(false)
            }
            if (stopAdClicked && adBreakInactive(gameEventPipeline)) {
                setStopAdClicked(false)
            }
            return () => {
                clearTimeout(timeoutRef.current)
            }
        }
        ,
        [gameEventPipeline, isEventActionUpdating, isTogglingSlate, startAdClicked, stopAdClicked]
    )

    useEffect(() => {
        const provisionedGamePk = cancelEvent?.gamePk
        if ((provisionedGamePk !== gamePk) || !isTogglingCancel || !gameEventPipeline) return

        if (cancelEvent.success) {
            addInfoAlertWithAutoRemoval("The event was cancelled successfully.")(dispatch)
            history.push('/')
        } else {
            setIsTogglingCancel(false)
            addErrorAlertWithAutoRemoval(cancelEvent.error)(dispatch)
        }
    }, [isTogglingCancel, cancelEvent, dispatch, gamePk, gameEventPipeline, history])


    async function handleEnableCaptionsChange() {
        await patchGamePipeline(gameEventPipeline, feedType, {"captionEnabled": !gameEventPipeline.captionEnabled})
    }

    const updateSlate = async (slateUri) => {
        setIsTogglingSlate(true)
        prevActiveSlate.current = gameEventPipeline?.activeSlate

        try {
            await applyYodaSlate(slateUri, gameEventPipeline)(dispatch)
        } catch (err) {
            if (err instanceof CerebroError) {
                addErrorAlertWithAutoRemoval(err.message)(dispatch)
            } else {
                addErrorAlertWithAutoRemoval('Something went wrong,'
                    + ' could not operate on event slate.')(dispatch)
            }
        }
    }


    const liftSlate = async () => {
        setIsTogglingSlate(true)
        prevActiveSlate.current = gameEventPipeline?.activeSlate

        try {
            await liftYodaSlate(gameEventPipeline)(dispatch)
        } catch (err) {
            if (err instanceof CerebroError) {
                addErrorAlertWithAutoRemoval(err.message)(dispatch)
            } else {
                addErrorAlertWithAutoRemoval('Something went wrong,'
                    + ' could not operate on event slate.')(dispatch)
            }
        }

    }


    const handleCancelEvent = async () => {
        setIsTogglingCancel(true)
        try {
            await cancelGame(gameEventPipeline)(dispatch)
        } catch (err) {
            if (err instanceof CerebroError) {
                addErrorAlertWithAutoRemoval(err.message)(dispatch)
            } else {
                addErrorAlertWithAutoRemoval(`Something went wrong, could not cancel game: ${gamePk}, feedType: ${feedType}`)(dispatch)
            }
        }
    }

    const handleStartStream = async () => {
        setIsEventActionUpdating(true)
        prevStatus.current = gameEventPipeline?.status

        try {
            await postGamePipelineStreamAction(gameEventPipeline, GameStreamAction.START)
        } catch (err) {
            setIsEventActionUpdating(false)
            console.log(`Could not start the stream ${err}`)

            if (err instanceof CerebroError) {
                addErrorAlertWithAutoRemoval(`Failed to start stream ${err.message}`)(dispatch)
            } else {
                addErrorAlertWithAutoRemoval('Something went wrong, could not start the stream.')(dispatch)
            }
        }
    }

    const handleStopStream = async () => {
        setIsEventActionUpdating(true)
        prevStatus.current = gameEventPipeline?.status

        try {
            await postGamePipelineStreamAction(gameEventPipeline, GameStreamAction.STOP)
        } catch (err) {
            setIsEventActionUpdating(false)
            console.log(`Could not stop the stream ${err}`)

            if (err instanceof CerebroError) {
                addErrorAlertWithAutoRemoval(`Failed to stop stream ${err.message}`)(dispatch)
            } else {
                addErrorAlertWithAutoRemoval('Something went wrong, could not stop the stream.')(dispatch)
            }
        }
    }

    const handleSyncFeeds = e => {
        e.preventDefault()
        if (preRef.current) {
            preRef.current.seekTo(preRef.current.getDuration() - 1)
        }
        if (postRef.current) {
            postRef.current.seekTo(postRef.current.getDuration() - 1)
        }
    }


    const handleOnStart = () => {
        if (isLive && preRef?.current?.getDuration() && postRef?.current?.getDuration()) {
            preRef.current.seekTo(preRef.current.getDuration() - 1)
            postRef.current.seekTo(postRef.current.getDuration() - 1)
        }
    }

    const handleCloneEvent = e => {
        e.preventDefault()
        const cloneGame = {
            ...gameEventPipeline,
        }
        history.push('/create-game', {gamePipeline: cloneGame})
    }

    const handleAdBreak = async (e, duration, type) => {
        e.preventDefault()
        if (type === SCTE_35_CUE_IN) {
            setStartAdClicked(false)
            setStopAdClicked(true)
            setTimeLeftAdInMillis(0)
            setTimeLapsedInSeconds(0)
            setHideTimer(true)
        } else {
            setStopAdClicked(false)
            setStartAdClicked(true)
            setTimeLeftAdInMillis((duration !== -1 ? (duration * 1000) : 0))
            setTimeLapsedInSeconds(0)
            setHideTimer(false)
        }
        try {
            await updateAdBreak(gameEventPipeline, duration, type)(dispatch)
        } catch (err) {
            if (type === SCTE_35_CUE_IN) {
                setStopAdClicked(true)
            } else {
                setStartAdClicked(true)
            }
            if (err instanceof CerebroError) {
                addErrorAlertWithAutoRemoval(`Failed to stop stream ${err.message}`)(dispatch)
            } else {
                addErrorAlertWithAutoRemoval('Something went wrong, could not stop the stream.')(dispatch)
            }
        }
    }

    let liftSlateBtn
    if (isLive || isProvisioned) {
        if (gameEventPipeline?.activeSlate) {
            liftSlateBtn = (
                <ButtonWithPopup
                    className={styles.liftSlateButton}
                    handleClick={() => liftSlate()}
                    btnText={
                        isTogglingSlate
                            ? <BeatLoader size={8} color={"#FFFFFF"} loading={isTogglingSlate}/>
                            : 'Lift Slate'
                    }
                    popupTitle='Deactivate Slate'
                    popupMsg='Are you sure you want to remove the following slate from this event?'
                    previewImg={currentSlateUrl}
                />
            )
        }
    }
    return (
        <div>
            <NavBar center={liftSlateBtn}/>
            <div style={{display: "grid", gridTemplateColumns: "1fr 1fr", gridGap: "20px"}}>
                <VideoPlayer started={!isProvisioned} playing={!isOver} url={playbackUrls.pre}
                             label={`Pre-Process ${gameEventPipeline?.feedType?.name}`} reference={preRef}
                             assetTypeCode={GAME_ASSET_TYPE.PRE}
                             accessToken={accessToken}
                             handleOnStart={handleOnStart}
                             isPrimary={primaryPlaybacks.pre}
                             forceLive={true}
                />
                <VideoPlayer started={!isProvisioned} playing={!isOver} url={playbackUrls.post}
                             label={`Post-Process ${gameEventPipeline?.feedType?.name}`} reference={postRef}
                             assetTypeCode={GAME_ASSET_TYPE.POST}
                             handleOnStart={handleOnStart}
                             isPrimary={primaryPlaybacks.post}
                             forceLive={false}
                />
            </div>
            {isLive && (
                <button
                    type='button'
                    className={`${styles.syncFeedsButton}`}
                    onClick={handleSyncFeeds}
                >
                    Sync Feeds
                </button>
            )}
            <form className={styles.form}>
                <div className={styles.contentContainer}>
                    <div className={styles.contentColumnLeft}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <FormGroup row className={`${styles.inputGroup} ${styles.noWrap}`}>
                                <TextField
                                    label="Game PK"
                                    disabled={true}
                                    value={gameEventPipeline?.gamePk || ""}
                                    style={{paddingRight: '2.25em', marginRight: '2em'}}
                                />
                                <DateField
                                    label="Day Of Game"
                                    value={gameEventPipeline?.gameDateTime || null}
                                />
                            </FormGroup>
                            <FormGroup row className={`${styles.inputGroup} ${styles.noWrap}`}>
                                <TimeField
                                    label="Stream Start Time"
                                    value={gameEventPipeline?.startedTime && !isEpochBeginning(gameEventPipeline?.startedTime) ? gameEventPipeline?.startedTime : gameEventPipeline?.plannedStartTime}
                                />
                                <TimeField
                                    label="Stream End Time"
                                    value={gameEventPipeline?.endedTime}
                                />
                            </FormGroup>
                            <FormGroup row className={`${styles.inputGroup} ${styles.noWrap}`}>
                                <TimeField
                                    label="Program Start Time"
                                    value={gameEventPipeline?.programStartTime && !isEpochBeginning(gameEventPipeline?.programStartTime) ? gameEventPipeline?.programStartTime : gameEventPipeline?.gameDateTime}
                                />
                                <TimeField
                                    label="Program End Time"
                                    value={gameEventPipeline?.programEndTime}
                                />
                            </FormGroup>
                        </LocalizationProvider>
                        <FormGroup className={styles.inputGroup}>
                            <LeagueDropdown
                                sportIdsSelected={gameEventPipeline?.feedType?.sportId ? [gameEventPipeline?.feedType?.sportId] : []}
                                supportedSportIds={milbSupportedSportIds}
                                disabled={true}
                            />
                        </FormGroup>
                        <FormGroup className={styles.inputGroup}>
                            <NextGenGameDropdown
                                gamePk={gamePk}
                                sportIds={sportIds}
                                startDate={gameDateTime}
                                disabled={true}
                                error={false}
                            />
                        </FormGroup>
                        <FormGroup className={styles.inputGroup}>
                            <VenueDropdown
                                venue={gameEventPipeline?.venueId}
                                override={false}
                            />
                        </FormGroup>
                        <StatusContent status={gameEventPipeline?.status}
                                       isEventActionUpdating={isEventActionUpdating}
                                       handleStartStream={handleStartStream}
                                       handleStopStream={handleStopStream}
                                       isOver={isOver}
                                       inProgress={gameEventPipeline?.inProgress}
                        />
                        <Collapsible defaultOpen={false} label="Edit EPG">
                            <EpgControls gamePk={gamePk}
                                         isStarted = {isStarted}
                            />
                        </Collapsible>
                    </div>
                    <div className={styles.contentColumnRight}>
                        {gameEventPipeline && <PipelineStatus pipeline={gameEventPipeline?.pipeline}
                                                              hasCaptions={gameEventPipeline?.captionEnabled}
                                                              status={gameEventPipeline?.status}
                                                              savedEncoder={gameEventPipeline?.ballparkEncoder}
                                                              homeTeamId={gameEventPipeline?.homeTeamId}
                                                              awayTeamId={gameEventPipeline?.awayTeamId}

                        />}
                    </div>
                    <div className={styles.contentColumnRight}>

                        {isLive &&
                            <Collapsible defaultOpen={true} label="Ad Break Controls">
                                <div className={styles.contentColumnRightItem}>
                                    <AdBreakButtons handleAdBreak={handleAdBreak}
                                                    adBreakActive={adBreakActive}
                                                    startAdClicked={startAdClicked}
                                                    adBreakDurationInSeconds={adBreakDurationInSeconds}
                                                    setAdBreakDurationInSeconds={setAdBreakDurationInSeconds}
                                                    stopAdClicked={stopAdClicked}
                                                    timeLeftAdInMillis={timeLeftAdInMillis}
                                                    timeLapsedInSeconds={timeLapsedInSeconds}
                                                    setTimeLeftAdInMillis={setTimeLapsedInSeconds}
                                                    setHideTimer={setHideTimer}
                                                    hideTimer={hideTimer}
                                    /></div>
                            </Collapsible>
                        }
                        <Collapsible defaultOpen={true} label="Available Slates">
                            <div className={styles.contentColumnRightItem}>
                                <YodaSlates yodaSlates={yodaSlates}
                                            updateSlate={updateSlate}
                                            liftSlate={liftSlate}
                                            activeSlate={gameEventPipeline?.activeSlate}
                                            isLive={isLive}
                                            isOver={isOver}/>
                            </div>
                        </Collapsible>
                        <div className={styles.inputGroupCheckbox} style={{justifyContent: "flex-start"}}>

                            <FormControlLabel
                                label="Captions"
                                control={
                                    <Checkbox
                                        checked={gameEventPipeline?.captionEnabled ? gameEventPipeline?.captionEnabled : false}
                                        onChange={handleEnableCaptionsChange}
                                        disabled={!isProvisioned}
                                    />
                                }
                            />

                        </div>
                        <div>
                            <button type='button' className={styles.cloneEventButton}
                                    onClick={handleCloneEvent}>
                                Clone Game
                            </button>
                            {
                                isProvisioned &&
                                <ButtonWithPopup type='button' className={styles.cancelEventButton}
                                                 handleClick={handleCancelEvent}
                                                 btnText={
                                                     isTogglingCancel
                                                         ?
                                                         <BeatLoader size={8} color={"#FFFFFF"}
                                                                     loading={isTogglingCancel}/>
                                                         : 'Cancel Game'
                                                 }
                                                 popupTitle={`Game Pk: ${gamePk}, Feed Type: ${feedType}`}
                                                 popupMsg='Are you sure you want to cancel the following game?'
                                />}
                        </div>
                    </div>
                </div>
            </form>
        </div>
    )
}

const DateField = ({label, value}) => {
    return (
        <DesktopDatePicker
            label={label}
            disabled={true}
            value={value || null}
            renderInput={(params) => <TextInput {...params} />}
            onChange={() => console.log}
        />
    )
}

const TimeField = ({label, value}) => {
    return (
        <DesktopTimePicker
            label={label}
            disabled={true}
            value={value || null}
            renderInput={(params) => <TextInput {...params} />}
            onChange={() => console.log}
        />
    )
}

const TextInput = params => {
    return <TextField {...params} style={{marginRight: "2em"}}/>
}
