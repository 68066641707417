import settings from "../settings";
import {CerebroError} from "../errors";
import { addErrorAlertWithAutoRemoval } from "./index"

const TRACTOR_BEAM_URL = `${settings.TRACTOR_BEAM_API_ROOT}/api/v1/epg`

const buildUrl = (gamePk) => {
    return `${TRACTOR_BEAM_URL}/league/MiLB/game/${gamePk}`
}

const toEpg = epgJson => {
    const epg = epgJson.map(e => ({
            "title": e?.title,
            "id": e?.items[0]?.id,
            "mediaId": e?.items[0]?.mediaId,
            "mediaState": e?.items[0]?.mediaState,
            "feedType": e?.items[0]?.mediaFeedType,
            "contentId": e?.items[0]?.contentId,
            "freeGame": e?.items[0]?.freeGame || false
        }))

    return epg.length > 0 ? epg[0] : {}
}

export const fetchEpg = async gamePk =>{
    const url = buildUrl(gamePk)
    console.log(`Fetching epg for gamePk: ${gamePk}`)
    try {
        const response = await fetch(url, {
            method: 'GET',
            mode: 'cors',
            credentials: 'omit',
            headers: {
                'Content-Type': 'application/json'
            }
        })
        if (!response.ok) {
            return response.text().then(text => {
                throw new CerebroError(`Could not fetch epg item for gamePk: ${gamePk}. HTTP Status: ${response.status}, Resp: ${text}`)
            })
        }

        const json = await response.json()

        return toEpg(json)
    } catch (err) {
        console.error(`Could not fetch epg item for gamePk '${gamePk}: ${err}`)
        throw err
    }
}

export const publishEpg = async (gamePk, epgItem) => {
    const url = buildUrl(gamePk)
    console.log(`Publishing epg for gamePk: ${gamePk}. Item:`, epgItem)
    try {
        const response = await fetch(url, {
            method: 'PUT',
            mode: 'cors',
            credentials: 'omit',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(epgItem)
        })

        if (!response.ok)
            return response.text().then(text => {
                throw new CerebroError(`Error updating the Epg Item. HTTP Status: ${response.status}, Resp: ${text}`)
            })
    } catch (err) {
        console.error(`Failed to update Epg Item for gamePk: ${gamePk}, epg: ${epgItem}, error: ${err}`)
        throw new CerebroError(`There was a problem updating the Epg Item ${err}`)
    }
}

export const clearEpg = async gamePk => {
    const url = buildUrl(gamePk)
    console.log(`Clearing epg for gamePk: ${gamePk}`)
    try {
        const response = await fetch(url, {
            method: 'DELETE',
            mode: 'cors',
            credentials: 'omit',
        })

        if (!response.ok)
            return response.text().then(text => {
                throw new CerebroError(`Error canceling the Epg Item. HTTP Status: ${response.status}, Resp: ${text}`)
            })
    } catch (err) {
        console.error(`Failed to cLEAR Epg Item for gamePk ${gamePk}. Error: ${err}`)
        throw new CerebroError(`There was a problem canceling the Epg Item ${err}`)
    }
}